<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex">
                <b-row>
                    <b-col sm="12" md="auto" class="mt-4">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-primary text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                    
                </b-row>
            </b-container>
        </div>

        <b-container fluid class="mt--6">
            <!-- Schedule Table -->
            <b-row class="mt-4">
                <b-col sm="12">
                    <HackathonScheduleTimeline :hackathon_id="hackathon_id" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
// Components
import HackathonScheduleTable from "@/views/Components/Hackathon/Schedule/ScheduleTable.vue";
import HackathonScheduleTimeline from "@/views/Components/Hackathon/Schedule/ScheduleTimeline.vue";

// Queries
// import {
//     GET_HACKATHON_IDEAS,
//     GET_HACKATHON_HACKATHON,
// } from "@/graphql/queries";

export default {
    name: "ViewHackathonSchedule",
    components: {
        HackathonScheduleTimeline,
    },
    apollo: {
        // get_hackathon_hackathon: {
        //     query: GET_HACKATHON_HACKATHON,
        //     result(res) {
        //         this.hackathon.name = res.data.hackathonHackathon.name;
        //         this.hackathon.description =
        //             res.data.hackathonHackathon.description;
        //         this.hackathon.detail_description =
        //             res.data.hackathonHackathon.detailDescription;
        //     },
        //     error(errors) {
        //         console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        //         console.log(errors.graphQLErrors);
        //         return false;
        //     },
        //     variables() {
        //         return {
        //             id: this.id,
        //         };
        //     },
        //     update(data) {
        //         this.apollo_data.get_hackathon_hackathon = data;
        //     },
        //     skip: true,
        // },
    },
    data() {
        return {
            apollo_data: {},
            hackathon_id: "null",
            // hackathon: {
            //     name: null,
            //     description: null,
            //     detail_description: null,
            // },
            // hackathon_ideas: [
            //     // {
            //     //     id: null,
            //     //     name: null,
            //     //     description: null,
            //     //     detail_description: null,
            //     //     number_of_likes: null,
            //     //     created: null,
            //     // },
            // ],
        };
    },
    methods: {
        // Apollo Methods
        enable_query(query) {
            if (!query.skip) {
                query.refetch();
            } else {
                query.skip = false;
                query.start();
            }
        },

        // ID Management
        get_hackathon_id() {
            if (this.$route.params.hackathon_id) {
                this.hackathon_id = this.$route.params.hackathon_id;
                // this.enable_query(this.$apollo.queries.get_hackathon_hackathon);
                // this.enable_query(this.$apollo.queries.get_all_hackathon_ideas);
            } else {
                this.hackathon_id = null;
                // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
            }
        },
    },
    mounted() {
        this.get_hackathon_id();
    },
};
</script>

<style>

</style>
